import { InfoCircleOutlined } from '@ant-design/icons';
import HorizontalBlank from '@pages/components/common/atoms/HorizontalBlank';
import { Button, Row, Space } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { FC, useState } from 'react';
import styled from 'styled-components';

interface DocumentGogeenInfoModalInterface {
  visible: boolean;
  onClose: () => void;
  onConfirm: (answer: boolean) => void; // Add onConfirm prop
}

const DocumentGogeenInfoModal: FC<DocumentGogeenInfoModalInterface> = ({ visible, onClose, onConfirm  }) => {
  const [safeModalVisible, setSafeModalVisible] = useState(false);
  
  const okHandle = (answer: boolean) => {
    onConfirm(answer);
  };
  return (
    <Modal title='GoGreen Plus 서비스를 이용해 보세요' visible={visible} onCancel={onClose} footer={null}>
      <p>
        DHL 외교부 긴급 여권 신청시 <RedFont>GoGreen Plus 서비스</RedFont>를 이용하시겠습니까?
      </p>
      <p>
        <RedFont>GoGreen Plus 서비스</RedFont>서비스는 지속가능한 항공 연료(Sustainable Aviation Fuel)을 사용해 고객님의 물품 운송 과정에서 발생하는 CO2 배출의 30%을 감소시켜 드리는 친환경 서비스입니다.
      </p>
      <p>
      지금 이용하시고 지속가능한 미래를 위한 실천에 동참해 보세요.
      </p>
      
      <div>
        <Space>
          <RedInfo />
          <span>서비스수수료 : 아시아: 190원, 유럽: 480원, 미주: 455원, 그외국가: 520원</span>
        </Space>
      </div>
      <HorizontalBlank height={20} />
      <Row justify={'end'}>
        <Space>
          <Button onClick={() => okHandle(true)}>예</Button>
          <Button onClick={() => okHandle(false)}>아니오</Button>
        </Space>
      </Row>
    </Modal>
  );
};
const RedFont = styled.span`
  color: red;
`;
const RedInfo = styled(InfoCircleOutlined)`
  color: red;
`;

export default DocumentGogeenInfoModal;
